.NotFound {
  font-family: "Rubik";
  font-weight: 700;
  display: grid;
  margin-top: 15%;
  .error-page-code {
    font-size: 120px;
    font-style: italic;
    color: #14025f;
    display: flex;
    justify-content: center;
  }
  .error-page-text {
    font-size: 44px;
    color: #4c36a5;
    display: flex;
    justify-content: center;
  }
}
