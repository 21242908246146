.AddNewSection {
  padding: 5px 15px 10px 25px;
  .add-new-label {
    display: flex;
    position: relative;
    justify-content: space-around;
    .add-new {
      color: #aaaaaa;
      width: 100%;
    }
    .close-new-btn {
      background: transparent;
      border: none;
      float: right;
    }
    hr {
      color: #aaaaaa;
      width: 60%;
    }
  }
  .add-new-buttons {
    margin-top: 30px;
    .new-application {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .new-app-btn {
        display: flex;
        align-items: center;
        height: 20px;
        align-content: center;
        background: transparent;

        border: none;
      }
      span {
        margin-left: 10px;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}
