.ToRetryItem {
  .retry-item {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 10px 30px 10px 30px;
    .success-status {
      color: #16bb76;
    }
    .error-section {
      .file-error {
        background: transparent;
        border: none;
      }
      .retry-btn {
        background: transparent;
        border: 1px solid #f35757;
        border-radius: 5px;
        color: #f35757;
        font-weight: 500;
      }
      .retry-btn-disabled {
        background: transparent;
        border: 1px solid #b3b3b3;
        border-radius: 5px;
        color: #b3b3b3;
        font-weight: 500;
      }
    }
  }
  .close-alert-error {
    display: flex;
    justify-content: center;
    .warning {
      background-color: #eae120;
    }
    .error {
      background-color: rgba(235, 60, 60, 0.7);
    }
    .close-btn {
      outline: none;
      padding: 5px 45px 5px 45px;
      border: none;
      border-radius: 50px;
      color: #fff;
    }
  }
}
