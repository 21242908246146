.info-card {
  .card {
    border: none !important;
    transition: 0.3s;
    width: 18rem;
    border-radius: 15px;
    display: flex;
    .card-body {
      display: flex;
      padding: 10px !important;

      svg {
        width: 60px !important;
        height: 60px !important;
      }
    }
  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }
}
