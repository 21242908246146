.SyncForm {
  .form-body {
    .sync-form-fields {
      min-height: 80px;
      display: flex;
      justify-content: center;
      .sync-submit-btn {
        position: relative;
        background: transparent;
        border: 2px solid #4c36a5;
        color: #4c36a5;
        padding: 8px 15px 8px 15px;
        font-weight: 600;
        border-radius: 5px;
        margin-top: 10.5%;
        &:hover {
          background: #4c36a5;
          color: #fff;
        }
      }
      .sync-submit-disabled {
        position: relative;
        background: transparent;
        border: 2px solid #9e9e9e;
        color: #bbbbbb;
        padding: 8px 15px 8px 15px;
        font-weight: 600;
        border-radius: 5px;
        margin-top: 10.5%;
        .sync-submit-loader {
          position: absolute;
          left: 28px;
          top: 5px;
        }
      }
      .form-group {
        margin-left: 2%;
        .form-control {
          border-radius: 5px;
          max-width: 588px;
          width: 100%;
          font-size: 22px;
          padding-left: 30px;
          &:focus {
            color: #212529;
            background-color: #fff;
            border-color: #6f53a3;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 2%);
          }
        }
        .was-validated .form-control:invalid,
        .form-control.is-invalid {
          background-image: none !important;
        }
        .invalid-feedback {
          display: block;
        }
      }
    }
  }
}
