.MaximoSetup {
  background: #fff;
  border-radius: 5px;
  padding: 180px 30px 80px 30px;
  margin-left: 220px;
  margin-right: 220px;
  margin-top: 5%;
  display: grid;
  gap: 60px;
  justify-items: center;
  .setup-header {
    .setup-title {
      display: flex;
      justify-content: center;
      text-align: center;
      .title {
        color: #4c36a5;
        font-size: 26px;
        font-weight: bold;
      }
    }
  }
  .setup-footer {
    display: grid;
    justify-items: center;
    .setup-loader {
      padding: 4% 0% 4% 0%;
    }
    .setup-errors {
      display: flex;
      padding: 30px;
      flex-direction: column;
      .message-errors-container {
        padding-bottom: 10px;
        display: flex;
        list-style: none;
        text-align: left;
        justify-content: flex-start;
        align-items: flex-start;

        .label-error {
          color: #4c36a5;
          padding-right: 15px;
          text-align: left;
        }
        .message-error {
          color: red;
          text-align: left;
        }
        .message-success {
          color: green;
          text-align: left;
        }
      }
    }
    .RetryOneButton{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      background: #eee;
      border: none;
      font-size: 10px;
      font-weight: 600;
      color: #333;
      padding: 4px 16px 4px 16px;
      margin-left: 10px;
    }
    .RetryButton {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      background: #eee;
      border: none;
      font-size: 20px;
      font-weight: 600;
      color: #333;
      padding: 8px 20px 8px 20px;
      .Retrybutton-text {
        margin-right: 15px;
      }
    }
  }
}
@media screen and (max-width: 1350px) {
  .MaximoSetup {
    margin-left: 45px;
    margin-right: 45px;
    padding: 80px 60px 80px 60px;
    .setup-header {
      .setup-title {
        .title {
          font-size: 23px;
        }
      }
    }
  }
}
