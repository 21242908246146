.toggle-container {
  width: 70px;
  background-color: #efefef;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 2px;
  height: 26px;
  position: relative;
}

.dialog-button {
  font-size: 14px;
  line-height: 10px;
  font-weight: bold;
  cursor: pointer;
  background-color: #4c36a5;
  color: white;
  padding: 6px 12px;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  min-width: unset;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 34px;
  transition: all 0.3s ease;
}

.disabled {
  background-color: #707070;
  left: 2px;
}
