.MaximoLogin {
  display: flex;
  justify-content: center;
  margin-top: 6%;
  .form-body {
    min-width: 40%;
    background: #fff;
    padding: 20px 30px 20px 30px;
    border-radius: 5px;
    .bloc-tabs {
      display: flex;
      justify-content: center;
      .tabs {
        min-height: 42px;
        text-transform: none;
        color: #555;
        background-color: #efefef;
        font-weight: 700;
        padding: 0rem 1.5rem 0rem 1.5rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size: 0.875rem;
        line-height: 1.25;
        letter-spacing: 0.02857em;
      }
      .saml-login-btn {
        border-bottom-right-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }
      .basic-login-btn {
        border-bottom-left-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
      }
      .active-tabs {
        background: white;
        color: #6957b5;
        border-bottom: 1px solid transparent;
      }

      button {
        border: none;
      }
    }
    .content-tabs {
      flex-grow: 1;
      .content {
        padding: 20px;
        width: 100%;
        height: 100%;
        display: none;
      }
      .content h2 {
        padding: 0px 0 5px 0px;
      }
      .content hr {
        width: 100px;
        height: 2px;
        background: #222;
        margin-bottom: 5px;
      }
      .content p {
        width: 100%;
        height: 100%;
      }
      .active-content {
        display: block;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .MaximoLogin {
    justify-content: start;
    .form-body {
      width: 90%;
    }
  }
}
@media screen and (max-width: 1669px) {
  .MaximoLogin {
    .form-body {
      min-width: 50%;
    }
  }
}
