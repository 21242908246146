.buttons-container {
  display: flex;

  button {
    padding: 2px 25px 2px 25px;
    background: transparent;
    border: 2px solid #4c36a5;
    // width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    border-radius: 15px;
    color: #4c36a5;
    font-weight: 600;
    font-size: 18px;
    &:hover {
      background: #4c36a5;
      border: none;
      padding: 4px 27px 4px 27px;
      border-radius: 15px;
      color: #fff;
    }
  }
}
