.FileUploader {
  padding-right: 20px;
  .file-input {
    display: none;
  }
  .file-button {
    background: transparent;
    border: none;
    padding: 5px;
    font-weight: 500;
    .import {
      font-size: 14px;
      margin-left: 8px;
    }
  }
}
