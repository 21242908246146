.ServerListItems {
  .accordion-item {
    border: none;
  }
  .accordion-body {
    padding: 0;
  }
  .accordion-button::before {
    margin-left: 5px !important;
  }
  ul.timeline {
    list-style-type: none;
    position: relative;
  }
  ul.timeline:before {
    content: " ";
    background: #e8e3fa;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 85%;
    z-index: 400;
    margin-top: 6%;
  }
  ul.timeline > li {
    margin: 0;
    padding-left: 20px;
  }
  ul.timeline > li:before {
    content: " ";
    background: #9f8fec;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    left: 25px;
    width: 10px;
    height: 10px;
    z-index: 400;
    margin-top: 6%;
  }
  ul.timelineprogress {
    list-style-type: none;
    position: relative;
  }
  ul.timelineprogress > li {
    margin: 0;
    padding-left: 20px;
  }
  li p {
    padding-left: 10%;
  }
  .liprogress {
    padding: 0.4rem 1.25rem;
  }
  button[aria-expanded="true"] {
    .icon-chevron {
      transform: rotate(0deg);
      transition: all 0.3s ease;
    }
  }
  button[aria-expanded="false"] {
    .icon-chevron {
      transform: rotate(-90deg);
      transition: all 0.3s ease;
    }
  }
  .icon-chevron {
    margin-right: 0.75rem;
    width: 18px;
    margin-right: 4px;
  }
  .icon-chevron-level1 {
    margin-right: 41px;
    margin-left: -25%;
  }
  .accordion-button:not(.collapsed) {
    color: #513ca7 !important;
    background-color: #f3f1fd !important;
  }
  .accordion-button::after {
    display: none;
  }
  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem 1.25rem;
    font-size: 16px;
    font-weight: 600;
  }
  h2 span {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 16px;
    font-weight: 600;
  }
  .colorLevel1 {
    background-color: #231365;
  }
  .colorLevel2 {
    background-color: #4c36a5;
  }
  .colorLevel3 {
    background-color: #9985fc;
  }
  .colorLevel3 {
    background-color: #afa0f8;
  }
  .h2progress2,
  .h2progress3,
  .h2progress4 {
    padding-top: 0.5rem !important;
  }
}
