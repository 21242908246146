.hardware {
  padding: 10px;
  .card {
    border: none !important;
    transition: 0.3s;
    width: 18rem;
    border-radius: 15px;
    display: flex;
    .card-title {
      font-size: 22px;
      padding-left: 10px;
      font-weight: 700;
    }
    .card-text {
      margin-bottom: 0 !important;
      .pourcentage {
        margin-top: 10%;
      }
      strong {
        font-size: 2.2rem;
        font-weight: 800;
        margin-left: 10;
        .go {
          font-size: 14px;
          display: contents;
        }
      }
      p {
        font-size: 14px;
        display: contents;
      }
      .icon {
        width: 50%;
        svg {
          width: 55px !important;
          height: 80px !important;
        }
      }
    }
    .progress {
      height: 0.7rem;
    }
  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }
}
