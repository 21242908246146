.PreRequest {
  background: #fff;
  border-radius: 5px;
  padding: 180px 0px 80px 0px;
  margin-left: 220px;
  margin-right: 220px;
  margin-top: 8%;
  .pre-install-header {
    .prequest-title {
      display: flex;
      justify-content: center;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
      .title {
        color: #222;
        font-size: 26px;
        font-weight: 500;
      }
    }
    .connect-btn {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }
  }
  .pre-install-footer {
    padding-top: 10%;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    justify-content: space-between;
  }
  .close-alert-error {
    display: flex;
    justify-content: center;
    .close-btn {
      background-color: rgba(235, 60, 60, 0.7);
      outline: none;
      padding: 5px 45px 5px 45px;
      border: none;
      margin: 0 5px;
      border-radius: 50px;
      color: #fff;
    }
  }
}

@media screen and (max-width: 1350px) {
  .PreRequest {
    margin-left: 45px;
    margin-right: 45px;
    .pre-install-header {
      .prequest-title {
        .title {
          font-size: 23px;
        }
      }
    }
  }
}
