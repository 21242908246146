.InstallerItem {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 5px 30px 5px 30px;
  .highlighted-script {
    background-color: #dcfcf1;
    border-radius: 5px;
  }
  .file-status {
    .file-error {
      background: transparent;
      border: none;
    }
    .file-success {
      cursor: default !important;
      background: transparent;
      border: none;
      div {
        cursor: default !important;
      }
    }
  }
  .close-alert-error {
    display: flex;
    justify-content: center;
    .warning {
      background-color: #eae120;
    }
    .error {
      background-color: rgba(235, 60, 60, 0.7);
    }
    .close-btn {
      outline: none;
      padding: 5px 45px 5px 45px;
      border: none;
      margin: 0 5px;
      border-radius: 50px;
      color: #fff;
    }
  }
}
