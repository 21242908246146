.DahsboardSideBar {
  .pro-sidebar {
    .pro-menu {
      a.active::before {
        background-color: rgba(0, 0, 0, 0.2);
        color: white;
      }
      .nav-link-disabled {
        color: #ffffff50;
        background: transparent;
        text-decoration: none;
        pointer-events: none;

        &:hover {
          .pro-inner-item {
            pointer-events: none;
          }
          color: gray !important;
        }
      }
    }
  }
}
