.IssueNavBar {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-left: 6%;
  p {
    margin-bottom: 0;
    color: #b9b9b9;
  }
  .card {
    border: none !important;
    transition: 0.3s;
    width: 8rem;
    height: 3rem;
    border-radius: 15px;
    display: flex;
    .card-body {
      display: flex;
      padding: 10px !important;

      svg {
        margin-top: 2px;
        width: 30px !important;
        height: 30px !important;
      }
    }
  }
  button {
    padding: 2px 25px 2px 25px;
    background: transparent;
    border: 2px solid #4c36a5;
    // width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    border-radius: 15px;
        color: #4c36a5;
    font-weight: 600;
    font-size: 18px;
    &:hover {
      background: #4c36a5;
      border: none;
      padding: 4px 27px 4px 27px;
      border-radius: 15px;
      color: #fff;
    }
  }
}
