.monitor-sidebar {
  height: 100vh;
  background-color: #ebe5f7;
  padding: 65px 0px 40px 0px;
  .monitor-sidebar-content {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    padding: 0px 20px 0px 20px;
    width: auto;
  }
}

.monitorSideBarr {
  display: flex;
  height: 100%;
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover,
  .pro-sidebar
    .pro-menu
    .pro-menu-item
    > .pro-inner-item
    > .pro-item-content:hover,
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
    color: #4c36a5 !important;
  }
  .pro-sidebar .pro-menu .pro-menu-item.active {
    color: #4c36a5 !important;
  }
}
$sidebar-bg-color: #1d1d1d;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #adadad;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;
@import "~react-pro-sidebar/dist/scss/styles.scss";
.pro-sidebar > .pro-sidebar-inner {
  background: #f3f1fe;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 16px 34px 16px 34px;
}
.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 16px;
  font-weight: 600;
}
.pro-sidebar {
  width: 230px;
  min-width: 220px;
}
.pro-sidebar {
  color: #000000;
}
#root {
  height: 100%;
}
.pro-sidebar-content .pro-menu .active {
  background: #ebe7f9;
}
.active-menu-item {
  color: #4c36a5;
  background-color: #EBE7F9;
}