.imx-logger-container {
  display: flex;
  flex-direction: column;
  margin: 4% 2%;
  .title {
    font-size: 24px;
    font-weight: 900;
  }
  .actions-buttons-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 1% 0%;
    .input-button {
      display: flex;
      margin: 1%;
      background: transparent;
      border: 2px solid #4c36a5;
      border-radius: 15px;
      color: #4c36a5;
      font-weight: 600;
      font-size: 14px;
      overflow: hidden;
      input {
        outline: none;
        border: 0px;
        padding: 4%;
      }
      button {
        font-weight: 600;
        font-size: 14px;
        background: transparent;
        color: #4c36a5;
        outline: none;
        border: 0px;
        &:hover {
          background: #4c36a5;
          color: #fff;
        }
      }
    }
    .action-button {
      padding: 1%;
      margin: 1%;
      background: transparent;
      border: 2px solid #4c36a5;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      color: #4c36a5;
      font-weight: 600;
      font-size: 14px;
      &:hover {
        background: #4c36a5;
        color: #fff;
      }
    }
  }
  .edit-config-title {
    display: flex;
    margin: 1%;
    align-items: center;
    justify-items: center;
    p {
      font-size: 24px;
      font-weight: 900;
    }
    .edit-config-buttons-container {
      display: flex;
      margin: 0% 2%;
      align-items: center;
      justify-items: center;

      button {
        margin: 0% 10%;

        padding: 5%;
        background: transparent;
        border: 1px solid #4c36a5;
        display: flex;
        align-items: center;
        justify-items: center;
        border-radius: 15px;
        color: #4c36a5;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        &:hover {
          margin: 10%;
          padding: 5%;

          background: transparent;
          display: flex;
          align-items: center;
          justify-items: center;
          border-radius: 15px;
          background: #4c36a5;
          border: 1px solid #4c36a5;
          color: #fff;
          font-weight: 600;
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
}
