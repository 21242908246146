.ServerBody {
  .progress-bar-text {
    color: #6f53a3;
    text-align: left;
    padding-left: 2%;
    font-weight: 600;
    font-size: 14px;
  }
}
.desc {
  padding: 2%;
  h5 {
    font-weight: 600;
    color: #6f53a3;
    padding-bottom: 5%;
  }
  span {
    font-weight: 600;
  }
}
