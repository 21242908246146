.ReinstallPrerequest {
  background: #fff;
  border-radius: 5px;
  padding: 180px 20px 80px 20px;
  margin-left: 220px;
  margin-right: 220px;
  margin-top: 8%;
  .reinstall-header {
    text-align: center;
    .reinstall-title {
      display: flex;
      justify-content: center;
      .title {
        color: #222;
        font-size: 26px;
        font-weight: 500;
      }
    }
  }
  .dialog-option {
    margin-top: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    .dialog-input {
      margin-right: 3%;
    }
  }
  .reinstall-footer-btn {
    padding: 10% 10% 0% 10%;
    display: flex;
    justify-content: space-between;
  }
  .confirm-uninstall {
    display: flex;
    justify-content: flex-end;
    margin-top: 20%;
    .cancel-btn {
      background-color: rgb(231, 231, 231);
      outline: none;
      padding: 10px 25px 10px 25px;
      border: none;
      margin: 0 5px;
      border-radius: 5px;
      color: #4c36a5;
    }
    .confirm-btn {
      background-color: #4c36a5;
      outline: none;
      padding: 10px 25px 10px 25px;
      border: none;
      color: #fff;
      border-radius: 5px;
      margin: 0 10px;
    }
  }
  .uninstall-progress {
    margin-top: 15%;
    display: grid;
  }
  .retry-uninstall {
    margin-top: 5%;
    display: grid;
    gap: 20px;
    .retry-grid {
    display: flex;
    justify-content: center;
    }
    .retry-message {
      color: #4c36a5;
      font-weight: bold;
      display: flex;
      max-height: 70px;
      justify-content: center;
      overflow-y: auto;
    }
    .retry {
      display: flex;
      justify-content: center;
      .retry-btn {
        background-color: #4c36a5;
        outline: none;
        padding: 10px 25px 10px 25px;
        border: none;
        color: #fff;
        border-radius: 5px;
        margin: 0 10px;
      }
    }
    .skip {
      display: flex;
      justify-content: center;
      .skip-btn {
        background-color: #4c36a5;
        outline: none;
        padding: 10px 25px 10px 25px;
        border: none;
        color: #fff;
        border-radius: 5px;
        margin: 0 10px;
      }
    }
  }
  .success-uninstall {
    margin-top: 10%;
    display: grid;
    gap: 20px;
    .success-title {
      color: #4c36a5;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      .show-error-details-btn {
        background: transparent;
        border: none;
      }
    }
    .finish {
      display: flex;
      justify-content: center;
      .finish-btn {
        background-color: #4c36a5;
        outline: none;
        padding: 10px 25px 10px 25px;
        border: none;
        color: #fff;
        border-radius: 5px;
        margin: 0 10px;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .ReinstallPrerequest {
    margin-left: 45px;
    margin-right: 45px;
    .reinstall-header {
      .reinstall-title {
        .title {
          font-size: 23px;
        }
      }
    }
  }
}
