.SubItem {
  .object-item {
    margin-left: 10px;
    position: relative;
    height: 90%;
  }
  .sub-drop-down {
    display: flex;
    .sub-collapse-btn {
      display: flex;
      align-items: center;
      width: 100%;
      background: transparent;
      border: none;
      padding-top: 10px;
      padding-bottom: 15px;
      border: none;
      padding-right: 10px;
      padding-left: 10px;
      text-align: left;
      .object-button {
        display: flex;
        align-items: center;
        .object-name {
          font-size: 16px;
          text-transform: capitalize;
        }
        .sub-item-label {
          padding: 1px 6px 1px 6px;
          margin-right: 8px;
          margin-left: 8px;
          border-radius: 5px;

          font-size: 10px;
          text-transform: lowercase;
        }
      }
    }
    .add-new {
      background: transparent;
      padding: 5px 10px 2px 10px;
      border: none;
      float: right;
    }
    .delete-item {
      background: transparent;
      border: none;
      padding: 5px 10px 2px 10px;
      float: right;
    }
    .add-left-border {
      height: 18px;
      width: 1px;
      margin-top: 18px;
      background-color: #6f53a350;
    }
    .selected {
      font-weight: 600;
      color: #6f53a3;
    }
  }
  .sub-selected-drop-down {
    background-color: #eee6ff;
    border-radius: 6px;
    display: flex;
    .sub-collapse-btn {
      display: flex;
      align-items: center;
      width: 100%;
      background: transparent;
      border: none;
      padding-top: 10px;
      padding-bottom: 15px;
      border: none;
      padding-right: 10px;
      padding-left: 10px;
      text-align: left;
      .object-button {
        display: flex;
        align-items: center;
        .object-name {
          font-size: 16px;
          text-transform: capitalize;
        }
        .sub-item-label {
          padding: 1px 6px 1px 6px;
          margin-right: 8px;
          margin-left: 8px;
          border-radius: 5px;

          font-size: 10px;
          text-transform: lowercase;
        }
      }
    }
    .add-new {
      background: transparent;
      padding: 5px 10px 2px 10px;
      border: none;
      float: right;
    }
    .delete-item {
      background: transparent;
      border: none;
      padding: 5px 10px 2px 10px;
      float: right;
    }
    .add-left-border {
      height: 18px;
      width: 1px;
      margin-top: 18px;
      background-color: #6f53a350;
    }
    .selected {
      font-weight: 600;
      color: #6f53a3;
    }
  }
}
