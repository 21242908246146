.Editor {
  padding-top: 15px;
  font-size: 14px;
  overflow-y: scroll;
  height: 100vh;
  position: relative;
  .jodit-container {
    border: none !important;
    padding-left: 15px;
    .submit-editor {
      display: flex;
      justify-content: flex-end;
      line-height: 30px;
      .submit-editor-btn {
        border-radius: 5px;
        margin-right: 40px;
        height: 35px;
        width: 35px;
      }
      .not-submitted-btn {
        border: 2px solid #04be61;
        background: transparent;
        color: #04be61;
      }
      .submitted-btn {
        border: 2px solid #04be61;
        background: #04be61;
        color: #fff;
      }
    }
    .jodit-container {
      border: none;
      line-height: 40px;
      padding-top: 5px;
      h1 {
        color: #6f53a3;
      }
      p {
        flex: 1;
        flex-direction: row;
      }
    }
    .editor-area {
      padding: 10px 20px 20px 30px;
      line-height: 50px;
      .item-value {
        margin-left: 50px;
      }
      &:focus {
        outline: none;
      }
    }
  }
}
