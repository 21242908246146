.Draft {
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
 flex: 1;
  .draft-btn {
    background-color: #ffedd5;
    flex: 0.9;
    border: none;
    border-radius: 25px;
    display: flex;
    padding: 5px 5px 5px 10px;
    align-items: center;
    .draft-label {
      display: flex;
     flex:0.8;
      align-items: center;
      border: none;
      background-color: transparent;
      span {
        margin-left: 5px;
        font-weight: 600;
        color: #6f53a3;
      }
      .input-draft{
        width: 60%;
        border: none;
        margin-left: 10px;
        background-color: transparent;
        border: 0;
        outline: 0;
        border-bottom: 2px solid #6f53a3;
      }
    }
    .publish-btn {
      flex:0.2;
      background-color: #efbf00;
      border-radius: 25px;
      border: none;
      padding: 4px 10px 4px 10px;
      color: #fff;
      font-weight: 600;
    }
  }
  .cancel-draft-btn {
    flex: 0.1;
    background: transparent;
    padding: 0px;
    border: none;
    cursor: pointer;
    padding: 0px;
  }
  .draft-suspendu {
    background-color: transparent;
    border: 2px solid #ffedd5;
    width: 90%;
    border-radius: 25px;
    display: flex;
    padding: 5px 5px 5px 10px;
    align-items: center;
    .draft-label {
      display: flex;
      width: 90%;
      align-items: center;
      border: none;
      background-color: transparent;
      span {
        margin-left: 5px;
        font-weight: 600;
        color: black;
      }
    }
    .publish-btn {
      width: 25%;
      background-color: #efbf00;
      border-radius: 25px;
      border: none;
      padding: 4px 10px 4px 10px;
      color: #fff;
      font-weight: 600;
    }
  }
}
