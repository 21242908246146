.InstallScreen {
  padding: 20px 140px 20px 140px;
  display: grid;
  gap: 40px;
  background: #fff;
  padding: 80px 100px 80px 100px;
  border-radius: 5px;
  margin-left: 220px;
  margin-right: 220px;
  margin-top: 5%;
  .install-progress {
    display: grid;
    gap: 30px;
    .progress-header {
      display: flex;
      gap: 20px;
      .progress-title {
        font-size: 18px;
        font-weight: 500;
      }
      .progress-status {
        color: #4c36a5;
        font-weight: 500;
      }
    }
  }
  .scripts-list {
    margin-bottom: 2%;
  }
  .response-error {
    height: 27px;
    display: grid;
    margin-top: 20px;
    .login-error {
      font-weight: 500;
      color: #df4759;
      font-size: 18px;
    }
  }
  .response-error {
    height: 27px;
    display: grid;
    margin-top: 20px;
    .login-error {
      font-weight: 500;
      color: #df4759;
      font-size: 18px;
    }
  }
  .response-error {
    height: 27px;
    display: grid;
    margin-top: 20px;
    .login-error {
      font-weight: 500;
      color: #df4759;
      font-size: 18px;
    }
  }
  .response-error {
    height: 27px;
    display: grid;
    margin-top: 20px;
    .login-error {
      font-weight: 500;
      color: #df4759;
      font-size: 18px;
    }
  }
  .control-btns {
    display: flex;
    justify-content: flex-end;
  }
  .close-alert-error {
    display: flex;
    justify-content: center;
    .close-btn {
      background-color: rgba(235, 60, 60, 0.7);
      outline: none;
      padding: 5px 45px 5px 45px;
      border: none;
      margin: 0 5px;
      border-radius: 50px;
      color: #fff;
    }
  }
}

@media screen and (max-width: 1350px) {
  .InstallScreen {
    margin-left: 45px;
    margin-right: 45px;
    padding: 80px 60px 80px 60px;
  }
}
