.Welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  .welcome-content {
    margin-top: 10%;
    padding: 80px 200px 80px 200px;
    .welcome-title {
      display: flex;
      justify-content: center;
      text-align: center;
      .welcome-title-text {
        color: #222;
        font-size: 26px;
        font-weight: 500;
      }
    }
    .start-btn {
      margin-top: 60px;
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 1057px) {
  .Welcome {
    .welcome-content {
      padding: 80px 45px 80px 45px;
      .welcome-title {
        .welcome-title-text {
          font-size: 23px;
        }
      }
    }
  }
}
