.dashboard-wrapper {
  font-family: "Rubik";
  display: flex;
  min-height: 94.6vh;
  background-color: #eee;
  body {
    margin: 0;
    height: 100vh;
    color: #353535;
  }
  .btn-toggle {
    cursor: pointer;
    display: none;
  }
  @media (max-width: 768px) {
    .btn-toggle {
      display: flex;
      margin-left: 2%;
      margin-top: 2%;
      svg {
        width: 50px;
        height: 27px;
      }
    }
  }
  .dashboard-body {
    width: 100%;
  }
}
