.usersCard {
  padding: 10px;
  .card {

    border: none !important;
    transition: 0.3s;
    width: 18rem;
    border-radius: 15px;
    display: flex;
    h1 {
      color: #4c36a5;
      font-weight: 700;
    }
    .card-body {
      display: flex;
      padding: 10px !important;
      .center {
        padding: 7px;
      }
    }
  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }
}
