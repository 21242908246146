body {
  background-color: #eee;
}
.signUp {
  margin-top: 150px !important;
  .signUpForm {
    img {
      max-height: 25px;
    }
    a {
      text-decoration: none;
      font-size: 14;
      font-weight: bold;
      color: #4c36a5;
    }
    .login-error {
      font-weight: 500;
      color: #df4759;
      font-size: 18px;
    }
    background-color: #fff;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    .conncetButton {
      .activeButton {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background: #4c36a5;
        border: none;
        font-size: 20px;
        font-weight: 600;
        color: #ffff;
        padding: 8px 20px 8px 20px;
        width: 450px;
        .forwardbutton-text {
          margin-right: 15px;
        }
        &:hover {
          box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.2),
            0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        }
      }
      .connectiondisabled {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        background: rgb(240, 240, 240);
        border: none;
        font-size: 22px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.726);
        padding: 5px 20px 5px 20px;
        .forwardbutton-text {
          margin-right: 15px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .signUp {
    display: flex;
    .signup-content {
      display: flex;
    }
  }
  .signUpImg {
    background-color: #4c36a5;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
}
@media (max-width: 990px) {
  .signUp {
    .signup-content {
      padding: 0% 10% 0% 10%;
    }
  }
}

@media (max-width: 767px) {
  .signUp {
    margin-top: 25px !important;
    .signUpForm {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }
  .signUpImg {
    background-color: #4c36a5;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}
