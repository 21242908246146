.text-date {
  padding: 2px;
  font-weight: 500;
  background-color: #f3f1fd;
  color: #6957b5;
}
.textInfo {
  padding: 2px;
  font-weight: 500;
  background-color: #e2fffd;
  color: #666e6e;
}
.service-text {
  color: #382973;
}
.listitems {
  margin-top: 10px;
}
.DebugHeader {
  display: flex;
  .icon {
    margin-top: 3px;
    margin-right: 1%;
  }
}
