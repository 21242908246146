.DashboardHeader {
  height: 50px;
  width: 100%;
  background-color: #fbfbfb;
  padding-top: 8px;
  padding-bottom: 5px;
  box-shadow: 2px 12px 6px 2px rgba(0, 0, 0, 0.5);
  .header-content {
    display: flex;
    justify-content: space-between;
    margin-left: 2%;
    padding-left: 20px;
    padding-right: 8%;
    img {
      height: 30px;
      min-width: 15%;
    }
    .header-content-right {
      display: flex;
      button {
        padding: 2px 15px 2px 15px;
        margin-left: 15px;
        background: transparent;
        border: 2px solid #4c36a5;
        border-radius: 5px;
        color: #4c36a5;
        font-weight: 600;
        font-size: 18px;
        &:hover {
          background: #4c36a5;
          border: none;
          padding: 4px 27px 4px 27px;
          border-radius: 5px;
          color: #fff;
        }
      }
      .maintenanceEnabled-btn {
        background: #4c36a5;
        margin-left: 15px;
        border: none;
        padding: 4px 27px 4px 27px;
        border-radius: 5px;
        color: #fff;
        &:hover {
          background: #4c36a5;
        }
      }
      .maintenanceDisable-btn {
        padding: 2px 15px 2px 15px;
        margin-left: 15px;
        background: transparent;
        border: 2px solid #4c36a5;
        border-radius: 5px;
        color: #4c36a5;
        font-weight: 600;
        font-size: 18px;
        &:hover {
          padding: 2px 15px 2px 15px;
          background: transparent;
          border: 2px solid #4c36a5;
          border-radius: 5px;
          color: #4c36a5;
        }
      }
    }
  }
  .confirm-dialog {
    width: 320px;
    min-height: 150px;
  }
  .confirm-uninstall {
    display: flex;
    justify-content: flex-end;
    margin-top: 13%;

    .cancel-btn {
      background-color: rgb(231, 231, 231);
      outline: none;
      padding: 10px 25px 10px 25px;
      border: none;
      margin: 0 5px;
      border-radius: 5px;
      color: #4c36a5;
    }
    .confirm-btn {
      background-color: #4c36a5;
      outline: none;
      padding: 10px 25px 10px 25px;
      border: none;
      color: #fff;
      border-radius: 5px;
      margin: 0 10px;
    }
  }
}
