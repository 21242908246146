.Preview {
  height: 88vh;
  .bar-title {
    padding-top: 9px;
    padding-bottom: 2px;
    border-bottom: 1px solid #a2a2a3;
    .revisionTitle {
      color: #6f53a3;
      margin-left: 10px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  .revision-content {
    padding-top: 50px;
    overflow-y: scroll !important;
    position: relative;
    height: 88vh;
  }
}
