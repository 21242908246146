.RetrySync {
  display: grid;
  justify-items: center;
  .retry-sync-error {
    margin-left: 30px;
    margin-top: 20px;
    color: #df4759;
    text-align: center;
  }
}
