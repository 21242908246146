.monitor-dashboard {
  .dashboard-infos {
    padding: 10px;
    display: flex;
    p {
      margin-bottom: 0;
      color: #b9b9b9;
    }
  }
}
