.Version {
  flex:1;
  .version-compo {
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex:1;
    .version-selected {
      background-color: #eee6ff;
      border: none;
      border-radius: 25px;
      .edit-version-btn {
        span {
          display: none;
        }
      }
    }
    .version-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
     flex:1;
      padding: 5px 5px 5px 10px;
      .version-btn {
        background: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        flex:1;        
        padding: 5px 5px 5px 10px;
        .version-name {
          flex:1;
          font-weight: 600;
          color: #888;
        }
      }
      .edit-version-btn {
        background: transparent;
        border: none;
       flex:0.1;
        padding: 4px 10px 4px 10px;
        span {
          display: none;
        }
      }

      .selected-btn {
        background: transparent;
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 5px 5px 10px;
        .version-name {
          font-weight: 600;
        }
        .input-draft{
          width: 40%;
          border: none;
          margin-left: 10px;
          background-color: transparent;
          font-weight: bold;
          border: 0;
          outline: 0;
          border-bottom: 2px solid #3e0870;
          font-weight: 900;
          color: #3e0870;
        }
      }
      .selected-version-btn {
        padding: 3px 15px 1px 15px;
        border: 2px solid #5cb85c;
        background-color: #fff;
        border-radius: 25px;
        align-items: center;
        justify-content: center;
        span {
          display: block;
          color: #5cb85c;
          font-weight: 600;
        }
      }
    }
    .versionselected-name {
      font-weight: 600;
      color: #6f53a3;
    }
    .cancel-draft-btn {
      display: none;
      background-color: transparent;
      border-width: 0;
    }
    &:hover {
      .cancel-draft-btn {
        display: inline;
        cursor: pointer;
        padding: 0px;
      }
      .version-wrapper {
        .edit-version-btn {
          padding: 3px 22px 3px 20px;
          border: 2px solid #5cb85c;
          background-color: #fff;
          border-radius: 25px;
          span {
            display: block;
            color: #5cb85c;
            font-weight: 600;
          }
        }
        .version-name {
          color: #444;
          font-weight: bold;
        }
      }
      .version-selected {
        .edit-version-btn {
          span {
            display: block;
            color: #5cb85c !important;
            font-weight: 600;
          }
        }
      }
    }
  }
}
