.PopUpList {
  padding: 0px 30px 0px 30px;
  ul {
    min-height: 80px;
    height: 80px;
    overflow-y: auto;
    li {
      font-size: 14px;
      color: rgb(187, 6, 6);
    }
  }
}
