.collectionBody {
  label {
    color: #5e5e5e;
  }
  svg {
    margin-left: 2%;
    margin-top: 3px;
    width: 25px !important;
    height: 30px !important;
  }
  .text-right {
    text-align: right;
  }
}
.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
