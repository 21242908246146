.IssueFilter {
  padding: 10px;
  label {
    color: #5e5e5e;
  }
  svg {
    margin-left: 2%;
    margin-top: 3px;
    width: 25px !important;
    height: 30px !important;
  }

  button {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }
}
