.IssueBody {
  label {
    color: #5e5e5e;
  }
  svg {
    margin-left: 2%;
    margin-top: 3px;
    width: 25px !important;
    height: 30px !important;
  }
  .text-right {
    text-align: right;
  }
  .accordion-button::after {
    transform: rotate(-90deg);
  }
  .accordion-button:not(.collapsed)::after {
    transform: rotate(-180deg) !important;
  }
}
.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
