.EditPane {
  .bar-title {
    padding-top: 15px;
    padding-bottom: 2px;
    align-items: baseline;
    border-bottom: 1px solid #a2a2a3;
    .propertiesTitle {
      color: #6f53a3;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
    }
    .submit-editor {
      height: 0px;
      padding-left: 10px;
      .submit-btn {
        padding: 0;
        padding-left: 10px;
        border: none;
        vertical-align: none;
        background: transparent;
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
  .update-displayed {
    padding-top: 11px;
  }
}
