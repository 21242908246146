.MailForm {
  .mail-form-container {
    display: grid;
    justify-items: center;
  .form-title {
    justify-self: flex-start;
    color: #111;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0px;
  }
  .form-group {

    margin-bottom: 15px;
    position: relative;
    &:focus-within .input-label {
      transform: translate(0, 6px) scale(0.8);
      color: #6f53a3;
    }
    .input-label {
      z-index: 9999;
      position: absolute;
      pointer-events: none;
      transform: translate(0, 23px) scale(1);
      transform-origin: top left;
      transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      color: #6f81a5;
      font-size: 22px;
      line-height: 1;
      left: 30px;
    }
    .filled {
      transform: translate(0, 6px) scale(0.8);
      font-size: 16px;
    }
    .form-control {
      border-radius: 5px;
      min-width: 588px;
      height: 64px;
      font-size: 22px;
      padding-left: 30px;
      &:focus {
        color: #212529;
        background-color: #fff;
        border-color: #6f53a3;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 2%);
      }
    }
    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      background-image: none !important;
    }
    .invalid-feedback {
      display: block;
    }
    .password-input {
      position: relative;
      align-items: center;
      justify-content: space-between;
      .show-password-btn {
        cursor: pointer;
        position: absolute;
        right:13%;
        top: 19px;
      }
    }
  }
  .form-options {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .response-error {
    min-height: 27px;
    max-width: fit-content;
    display: grid;
    margin-top: 20px;
    .login-error {
      font-weight: 500;
      color: #df4759;
      font-size: 18px;
      word-break: break-word;
    }
  }
  .submit {

    display: flex;
    justify-content: flex-end;
  }
  
}
}
