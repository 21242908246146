$sidebar-bg-color: #6f53a3 !important;
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #ffffff;
$highlight-color: #d8d8d8;
$icon-size: 35px;

.DahsboardSideBar {
  .pro-sidebar {
    color: #ffffff !important;
    width: 250px;
    min-width: 230px;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding: 16px 34px 16px 22px;
  }
  .pro-sidebar {
    text-decoration: none;
    color: #ffffff !important;
    .pro-menu {
      padding-top: 60px;
      padding-bottom: 10px;
      a {
        text-decoration: none;
        color: #ffffff !important;
      }
    }
  }
  .pro-sidebar > .pro-sidebar-inner {
    background: #4c36a5 !important;
    height: 100%;
    position: relative;
    z-index: 101;
  }
  .pro-sidebar .pro-menu .pro-menu-item {
    font-size: 20px;
    font-weight: 600;
    .collapse-menu {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
