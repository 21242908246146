.BackButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background: rgb(240, 240, 240);
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  padding: 8px 35px 8px 25px;
  .backbutton-text {
    margin-left: 10px;
  }
  &:hover {
    background: rgb(230, 230, 230);
  }
}
.Backdisabled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background: rgb(240, 240, 240);
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.726);
  padding: 3px 25px 3px 15px;

  .backbutton-text {
    margin-left: 10px;
  }
}
