.DebugIndex {
  .btn {
    color: #595959;
    background-color: #e8e8e8;
    font-weight: 700;
    padding: 0rem 0.5rem 0rem 0.1rem;
    border-radius: 0.5rem;
  }

  .bloc-tabs {
    display: flex;
    padding-left: 3%;
  }
  .tabs {
    min-height: 42px;
    text-transform: none;
    color: #595959;
    background-color: #e8e8e8;
    margin: 0.5rem !important;
    font-weight: 700;
    padding: 0rem 1rem 0rem 1rem;
    border-radius: 0.5rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.875rem;
    line-height: 1.25;
    letter-spacing: 0.02857em;
  }

  .active-tabs {
    background: white;
    color: #6957b5;
    border-bottom: 1px solid transparent;
  }

  button {
    border: none;
  }
  .content-tabs {
    flex-grow: 1;
  }
  .content {
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
  }
  .content h2 {
    padding: 0px 0 5px 0px;
  }
  .content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }
  .content p {
    width: 100%;
    height: 100%;
  }
  .active-content {
    display: block;
  }
}
