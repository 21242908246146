.bugserver {
  width: 500px;
  height: 50px;
  padding-left: 10%;
  display: table-cell;
  vertical-align: middle;
  background-color: white;
}
.bugdate {
  width: 500px;
  height: 50px;
  display: table-cell;
  vertical-align: middle;
  padding-left: 7%;
}
.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: white !important ;
  box-shadow: none !important;
}
.accordion-button::after {
  margin-left: 14px;
  transform: rotate(-90deg);
}
.accordion-body {
  padding-top: 5px;
}
.accordion-button[aria-expanded="true"] {
  padding-bottom: 0;
}
.accordion-button:focus {
  box-shadow: none;
}
.span-date {
  padding: 1rem 1.25rem 0rem;
  span {
    font-size: 1rem;
    white-space: nowrap;
    color: #959595;
  }
}
.side-icon {
  width: 4%;
}
