.DetailsListHeader {
  font-weight: bold;
  color: #4c36a5;
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 5px;
  .show-details-button {
    display: flex;
    p {
      color: #858585;
      font-size: 16px;
    }
    .show-error-details-btn {
      background: transparent;
      border: none;
    }
  }
}
