.InstalledItem {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 5px 30px 5px 2px;
}
.highlighted-script {
  background-color: #dcfcf1;
  border-radius: 5px;
}
.file-status {
  color: #9b9b9b;
}
.file-status-failed{
  color: #e63947
}
