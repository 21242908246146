.DisconnectScreen {
  background: #fff;
  padding: 80px 100px 160px 100px;
  border-radius: 5px;
  margin-left: 220px;
  margin-right: 220px;
  margin-top: 5%;
  gap: 15%;
  display: grid;
  .disconnect-screen-header {
    gap: 15%;
    display: grid;
    justify-content: center;
    text-align: center;
    .disconnect-screen-title {
      color: #222;
      font-size: 22px;
    }

    .server-url {
      min-height: 50px;
      justify-self: center;
      color: #4c36a5;
      font-size: 22px;
      font-weight: bold;
    }
  }
  .disconnect-footer {
    display: flex;
    gap: 20px;
    justify-content: center;
    .disconnect-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      background: #f5f3fe;
      border: none;
      font-size: 20px;
      font-weight: bold;
      color: #000;
      padding: 8px 25px 8px 25px;
      .disconnect-button-text {
        margin-right: 10px;
      }
      &:hover {
        background: #ece8fd;
      }
    }
    .clear-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      background: #6f53a3;
      border: none;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      padding: 5px 35px 5px 35px;
      .clear-button-text {
        margin-right: 10px;
      }
      &:hover {
        box-shadow: 0px 0px 0px -1px rgba(0, 0, 0, 0.2),
          0px 4px 5px 0px rgba(0, 0, 0, 0.14),
          0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }
  .dialog-option {
    margin-top: 2%;
    display: flex;
    align-items: center;

    .dialog-input {
      margin-right: 3%;
    }
  }
  .confirm-uninstall {
    display: flex;
    justify-content: flex-end;
    margin-top: 13%;

    .cancel-btn {
      background-color: rgb(231, 231, 231);
      outline: none;
      padding: 10px 25px 10px 25px;
      border: none;
      margin: 0 5px;
      border-radius: 5px;
      color: #4c36a5;
    }
    .confirm-btn {
      background-color: #4c36a5;
      outline: none;
      padding: 10px 25px 10px 25px;
      border: none;
      color: #fff;
      border-radius: 5px;
      margin: 0 10px;
    }
  }
  .uninstall-progress {
    margin-top: 15%;
    display: grid;
  }
  .retry-uninstall {
    margin-top: 1%;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    padding: 5% 0% 5% 0%;

    .retry-message {
      color: #4c36a5;
      font-weight: bold;
      margin-left: 5px;
      display: flex;
      max-height: 100px;
      height: auto;
      overflow-y: auto;
      width: auto;
    }
    .retry {
      display: flex;
      align-items: center ;
      justify-content: center;
      .retry-btn {
        align-items: center ;
        justify-content: center;
        background-color: #4c36a5;
        outline: none;
        padding: 10px 25px 10px 25px;
        border: none;
        color: #fff;
        border-radius: 5px;
        height: 50px;
        margin: 0 10px;
      }
    }
  }
  .success-uninstall {
    margin-top: 10%;
    display: grid;
    gap: 20px;
    .success-title {
      color: #4c36a5;
      font-weight: bold;
      display: flex;
      justify-content: center;
    }
    .finish {
      display: flex;
      justify-content: center;
      .finish-btn {
        background-color: #4c36a5;
        outline: none;
        padding: 10px 25px 10px 25px;
        border: none;
        color: #fff;
        border-radius: 5px;
        margin: 0 10px;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .DisconnectScreen {
    margin-left: 45px;
    margin-right: 45px;
    padding: 80px 60px 160px 60px;
  }
}
