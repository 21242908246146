.ToRetryList {
  display: flex;
  justify-content: center;
  .scripts-box {
    width: 100%;
    max-height: 220px;
    border: none;
    border-radius: 10px;
    overflow-y: scroll;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f4ecf7;
    border-radius: 100px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #6f53a3;
    padding: 0 4px;
    border-right: 2px solid transparent;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    background-clip: padding-box;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #6f53a3;
  }
}
