.css-n0o05t-MuiPaper-root-MuiAppBar-root {
  font-family: "Rubik";
  background-color: transparent !important;
  box-shadow: none !important;
}
.css-129qkci-MuiDrawer-docked .MuiDrawer-paper {
  left: 10%;
}
.monitorBody {
  display: flex;
  height: 94.6vh;
  .content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

p,
ul,
li,
div,
nav {
  padding: 0;
  margin: 0;
}

body {
  font-family: Calibri;
}

#menu {
  overflow: auto;
  position: relative;
  z-index: 2;
}

.parent-menu {
  background-color: #0c8fff;
  min-width: 200px;
  float: left;
}

#menu ul {
  list-style-type: none;
}

#menu ul li a {
  padding: 10px 15px;
  display: block;
  color: #fff;
  text-decoration: none;
}

#menu ul li a:hover {
  background-color: #007ee9;
}

#menu ul li:hover > ul {
  left: 200px;

  -webkit-transition: left 200ms ease-in;

  -moz-transition: left 200ms ease-in;

  -ms-transition: left 200ms ease-in;

  transition: left 200ms ease-in;
}

#menu ul li > ul {
  position: absolute;

  background-color: #333;

  top: 0;

  left: -200px;

  min-width: 200px;

  z-index: -1;

  height: 100%;

  -webkit-transition: left 200ms ease-in;

  -moz-transition: left 200ms ease-in;

  -ms-transition: left 200ms ease-in;

  transition: left 200ms ease-in;
}

#menu ul li > ul li a:hover {
  background-color: #2e2e2e;
}
