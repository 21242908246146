.Header {
  display: flex;
  padding-top: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgb(226, 226, 226);
  justify-content: flex-end;
  .file-actions {
    display: flex;
    align-items: center;
    margin-right: 100px;
    justify-content: center;

    .export-btn {
      padding-right: 20px;
      padding-top: 1px;
      background: transparent;
      border: none;
      font-weight: 500;
      .export {
        font-size: 14px;
        margin-left: 8px;
      }
    }
    .create-new-btn {
      padding-top: 1px;
      background: transparent;
      border: none;
      font-weight: 500;
      .create {
        font-size: 14px;
        margin-left: 8px;
      }
    }
  }
}
