.PostInstall {
  padding: 20px 140px 20px 140px;
  display: grid;
  background: #fff;
  padding: 80px 100px 80px 100px;
  border-radius: 5px;
  margin-left: 220px;
  margin-right: 220px;
  margin-top: 5%;
  .posinstall-header {
    display: flex;
    gap: 20px;
    .posinstall-title {
      font-size: 26px;
      font-weight: bold;
      color: #6f53a3;
    }
  }
  .scripts-list {
    margin-bottom: 2%;
    margin-top: 1%;
  }
  .control-btns {
    display: flex;
    justify-content: flex-end;
  }
  .close-alert-error {
    display: flex;
    justify-content: center;
    .close-btn {
      background-color: rgba(235, 60, 60, 0.7);
      outline: none;
      padding: 5px 45px 5px 45px;
      border: none;
      margin: 0 5px;
      border-radius: 50px;
      color: #fff;
    }
  }
}
@media screen and (max-width: 1350px) {
  .PostInstall {
    margin-left: 45px;
    margin-right: 45px;
    padding: 80px 60px 80px 60px;
    .posinstall-header {
      .posinstall-title {
        font-size: 23px;
      }
    }
  }
}
