.InstallationVerification {
  background: #fff;
  border-radius: 5px;
  padding: 180px 30px 80px 30px;
  margin-left: 220px;
  margin-right: 220px;
  margin-top: 5%;
  .verification-header {
    .verification-title {
      display: flex;
      justify-content: center;
      text-align: center;
      .title {
        color: #4c36a5;
        font-size: 26px;
        font-weight: bold;
      }
    }
  }
  .verification-footer-btn {
    justify-content: center;
    .verif-next-btn {
      display: flex;
      justify-content: center;
      padding: 5% 0% 5% 0%;
    }
    .verif-loader {
      padding: 4% 0% 4% 0%;
    }
    .sync-error-message {
      margin-left: 30px;
      margin-top: 20px;
      color: #df4759;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1350px) {
  .InstallationVerification {
    margin-left: 45px;
    margin-right: 45px;
    padding: 80px 60px 80px 60px;
    .verification-header {
      .verification-title {
        .title {
          font-size: 23px;
        }
      }
    }
  }
}
