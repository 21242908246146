.loading-page {
  margin-top: 18%;
  .close-alert-error {
    display: flex;
    justify-content: center;
    .close-btn {
      background-color: rgba(235, 60, 60, 0.7);
      outline: none;
      padding: 5px 45px 5px 45px;
      border: none;
      border-radius: 50px;
      color: #fff;
    }
  }
}
