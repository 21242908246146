.RetrySyncButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background: #4c36a5;
  border: none;
  font-size: 20px;
  font-weight: 600;
  color: #ffff;
  padding: 8px 20px 8px 20px;
  .retrysyncbutton-text {
    margin-right: 15px;
  }
  &:hover {
    background: #3e2a8d;
  }
}
.RetrySyncButtonDisabled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background: #4c36a581;
  border: none;
  font-size: 22px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.726);
  padding: 5px 8px 5px 8px;
  .retrysyncbutton-text {
    margin-right: 15px;
  }
}
